import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
    key: 0,
    id: "alert-banner"
};
const _hoisted_2 = {
    type: "h2",
    color: "container-header-on-primary"
};
const _hoisted_3 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_alert = _resolveComponent("alert");
    return (_ctx.alerts)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alerts, (alert, index) => {
                return (_openBlock(), _createBlock(_component_alert, { key: index }, {
                    "alert-message": _withCtx(() => [
                        _createTextVNode(_toDisplayString(alert.summary), 1 /* TEXT */)
                    ]),
                    "modal-header": _withCtx(() => [
                        _createElementVNode("spk-text", _hoisted_2, _toDisplayString(alert.summary), 1 /* TEXT */)
                    ]),
                    "modal-body": _withCtx(() => [
                        _createElementVNode("spk-text", {
                            innerHTML: alert.answer,
                            color: "container-body-on-primary",
                            class: "modal-body"
                        }, null, 8 /* PROPS */, _hoisted_3)
                    ]),
                    _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */));
            }), 128 /* KEYED_FRAGMENT */))
        ]))
        : _createCommentVNode("v-if", true);
}
