import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = { class: "alert-container" };
const _hoisted_2 = {
    color: "container-link-on-warning",
    href: "#",
    size: "xs"
};
const _hoisted_3 = {
    key: 0,
    class: "background-dimmer"
};
const _hoisted_4 = { class: "modal" };
const _hoisted_5 = { style: { "padding": "32px" } };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("spk-alert", {
            hideicon: "",
            color: "warning",
            align: "center",
            onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.showModal = true)),
            class: "alert-message"
        }, [
            _createElementVNode("spk-link", _hoisted_2, [
                _renderSlot(_ctx.$slots, "alert-message")
            ])
        ]),
        _createVNode(_Transition, { name: "fade" }, {
            default: _withCtx(() => [
                (_ctx.showModal)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createElementVNode("spk-container", _hoisted_4, [
                            _createElementVNode("div", _hoisted_5, [
                                _createElementVNode("spk-icon", {
                                    size: "32",
                                    name: "close",
                                    color: "container-on-primary",
                                    class: "close-icon",
                                    onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.showModal = false))
                                }),
                                _renderSlot(_ctx.$slots, "modal-header"),
                                _renderSlot(_ctx.$slots, "modal-body")
                            ])
                        ])
                    ]))
                    : _createCommentVNode("v-if", true)
            ]),
            _: 3 /* FORWARDED */
        })
    ]));
}
